import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { DashboardLaborHoursResponse } from 'src/app/dashboard/shared/area.models';
import { ApiService } from 'src/app/shared/services/api.service';

import { TapVarianceResult } from '../models/productivity.models';

@Injectable()
export class ProductivityService {
  constructor(private api: ApiService) {}

  public getStafing(clinicId, startDate, noOfWeeks: string): Observable<TapVarianceResult> {
    return this.getNodeProvidedData(clinicId, startDate, noOfWeeks);
  }

  getNodeProvidedData(clinicId: string, startDate: Date, noOfWeeks: string): Observable<TapVarianceResult> {
    const queryParams = new HttpParams()
      .append('numberOfWeeks', noOfWeeks)
      .append('startDate', DateTime.fromJSDate(startDate).toISODate());

    return this.api.makeRequest('get', `tap/${clinicId}`, { queryParams, showIndicator: false, ignoreErrors: true });
  }

  public staffingDetails(
    res: TapVarianceResult,
    agencyHours: DashboardLaborHoursResponse[][],
  ): TapVarianceResult | null {
    if (!res || res === null) {
      return null;
    }
    // Get rid of the extra garbage rows
    res.staffingDetails = res.staffingDetails?.filter((detail) => detail.name === 'RN' || detail.name === 'PCT');

    const rn = res.staffingDetails?.find((type) => type.name === 'RN');
    if (rn) {
      const rnAgency = agencyHours[0].reduce((total, current) => (total += current.RNActAgency), 0);
      rn.agencyActual = rnAgency;
    }

    const pct = res.staffingDetails?.find((type) => type.name === 'PCT');
    if (pct) {
      const pctAgency = agencyHours[0].reduce((total, current) => (total += current.PCTActAgency), 0);
      pct.agencyActual = pctAgency;
    }
    return res;
  }
}
