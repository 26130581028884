import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { firstValueFrom } from 'rxjs';
import { hourDropdownOptions, minuteDropdownOptions } from 'src/app/shared/models/primeNg';
import { IClinicShedule } from 'src/app/swac/core/services/swac-clinic.service';
import { PatientsService } from 'src/app/swac/patients/patients.service';

import {
  covidOptions,
  hepOptions,
  IPatient,
  IPatientUploadData,
  IPDropdownOptions,
  IVLPatientData,
} from '../models/patient';
import { VirtualLobbyService } from '../services/virtual-lobby.service';

@Component({
  standalone: true,
  selector: 'app-patient-modal',
  templateUrl: './patient-modal.component.html',
  styleUrls: ['./patient-modal.component.scss'],
  imports: [FormsModule, ButtonModule, DropdownModule, CalendarModule, CommonModule, DialogModule, InputTextareaModule],
})
export class PatientModalComponent implements OnInit {
  public hepOptions = hepOptions;
  public covidOptions = covidOptions;

  public mrn: string;

  public patientTypeOptions: IPDropdownOptions[] = [
    { value: 1, label: 'Permanent (ESRD)' },
    { value: 2, label: 'Transient' },
    { value: 3, label: 'Transfer' },
    { value: 4, label: 'AKI (non-ESRD)' },
    { value: 5, label: 'Disaster' },
  ];
  public patientUploadData: IPatientUploadData = {
    hour: 4, //default 4 hours
    minute: 0,
  };
  public patient: IPatient;
  public fullName: string;
  public dateOfBirth: string;
  public covidDisabled = false;
  public hepDisabled = false;
  public ISECUBEDURATION = 0;
  public todayDate = new Date();
  public showHepWarning: boolean;

  public hourOptions = hourDropdownOptions;
  public minuteOptions = minuteDropdownOptions;

  private submitFunc: (uploadData: IPatientUploadData) => Promise<void>;
  public showMrn = false;
  public showOpenChairs = false;
  public openChairsOptions: IPDropdownOptions[] = [];
  public openChairs: { [key: number]: IClinicShedule } = {};
  public medicalRecordNumber = '--';

  constructor(
    private virtualLobbyService: VirtualLobbyService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private swacPatientsService: PatientsService,
  ) {}

  ngOnInit(): void {
    this.patientUploadData.legacyIdCenter = this.config.data.legacyIdCenter;
    this.submitFunc = this.config.data.submit;
    this.showMrn = this.config.data.showMrn;
    this.showOpenChairs = this.config.data.showOpenChairs;
    if (this.showOpenChairs) {
      this.getOpenChairs();
    }
    if (this.config.data.patient) {
      this.setInputPatient(this.config.data.patient);
    }
  }

  async lookupMRN() {
    const res = await firstValueFrom(this.virtualLobbyService.getPatientByMrn(this.mrn));
    try {
      this.patientUploadData.patientId = res.id;

      this.medicalRecordNumber = res.medicalRecordNumber;

      this.fullName = [res.firstName, res.middleName, res.lastName].filter((name) => name).join(' ');
      this.dateOfBirth = DateTime.fromISO(res.dob, { zone: 'utc' }).toFormat('MM/dd/yyyy');

      if (res.hep) {
        this.hepDisabled = true;

        // allow 'Immune' and 'Naturally Immune' to both be 'Immune' in dropdown
        this.patientUploadData.hep = this.hepOptions.find((hep) =>
          res.hep.includes(hep.PatientDiseaseStatus),
        )?.PatientDiseaseStatus_Id;

        // show hep warning
        if (this.patientUploadData.hep === 4) {
          this.showHepWarning = true;
        }
      } else {
        this.hepDisabled = false;
      }

      if (res.covid19StatusId) {
        this.covidDisabled = true;

        // set the status
        this.patientUploadData.covidStatus = res.covid19StatusId;
      } else {
        this.covidDisabled = false;
      }
    } catch (ex) {
      this.messageService.add({ severity: 'error', detail: 'MRN Lookup parsing failed.' });
    }
  }

  private getOpenChairs() {
    this.swacPatientsService.getClinicByIdCenter(this.patientUploadData.legacyIdCenter).subscribe({
      next: (clinic) => {
        for (const schedule of clinic.schedules) {
          // this chair is blocked so ignore it
          if (schedule.blocked === 1) {
            continue;
          }
          let weekdayString = 'MWF';
          if (schedule.weekDayId === 2) {
            weekdayString = 'TTS';
          }
          this.openChairsOptions.push({
            value: schedule.scheduleId,
            label: `${weekdayString} - ${schedule.podName} - Chair ${schedule.chairName} - Shift ${schedule.shiftNumber} - ${schedule.startTime}-${schedule.endTime}`,
          });
          this.openChairs[schedule.scheduleId] = schedule;
        }
      },
    });
  }

  public resetTransient() {
    this.patientUploadData.hour = 4;
    this.patientUploadData.minute = 0;
    delete this.patientUploadData.treatments;
    delete this.patientUploadData.endDate;
  }

  private setInputPatient(patient: IVLPatientData) {
    this.medicalRecordNumber = patient.mrn;

    this.patientUploadData.idPatientMaster = patient.IdPatientMaster;
    this.patientUploadData.treatments = patient.numberOfTreatments;
    this.patientUploadData.notes = patient.notes;
    this.patientUploadData.patientType = patient.patientTypeId;
    this.patientUploadData.startDate = patient?.startDate?.split('T')?.[0].replace(/-/g, '/');
    this.patientUploadData.endDate = patient?.endDate?.split('T')?.[0].replace(/-/g, '/');
    this.patientUploadData.hour = Math.floor(patient.durationInMinutes / 60);
    this.patientUploadData.minute = patient.durationInMinutes % 60;
    this.patientUploadData.patientLobbyId = patient.patientLobbyId;
    this.patientUploadData.scheduleId = patient.scheduleId;

    this.patientUploadData.firstName = patient.firstName;
    this.patientUploadData.middleName = patient.middleName;
    this.patientUploadData.lastName = patient.lastName;
    this.patientUploadData.mrn = patient.mrn;
    this.patientUploadData.idPatientMaster;

    // set hep and covid
    const disease = patient.patientDiseaseStatusId?.split(',');
    this.patientUploadData.hep = +disease?.[0] || undefined;
    this.patientUploadData.covidStatus = +disease?.[1] || undefined;

    this.fullName = [patient?.firstName, patient?.middleName, patient?.lastName].filter((name) => name).join(' ');
    this.dateOfBirth = DateTime.fromISO(patient.dob, { zone: 'utc' }).toFormat('MM/dd/yyyy');

    if (patient.IsEcubeHepStatus) {
      this.hepDisabled = true;
    } else {
      this.hepDisabled = false;
    }

    if (patient.IsEcubeCovid19Status) {
      this.covidDisabled = true;
    } else {
      this.covidDisabled = false;
    }
  }

  async formSubmit(form: NgForm) {
    if (
      form.invalid ||
      (this.medicalRecordNumber === '--' && !this.mrn) ||
      (this.patientUploadData.hour === 0 &&
        this.patientUploadData.minute === 0 &&
        this.patientUploadData.patientType == 2)
    ) {
      return;
    }

    if (this.medicalRecordNumber === '--' && this.mrn) {
      await this.lookupMRN();
    }

    if (this.patientUploadData.hour === 0 && this.patientUploadData.minute === 0) {
      this.patientUploadData.hour = 4;
      this.patientUploadData.minute = 0;
    }

    await this.submitFunc(this.patientUploadData);
    this.ref.close(true);
  }
  dismissDialog() {
    this.ref.close(false);
  }
}
