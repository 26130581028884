<div>
  <div class="p-fluid">
    <form class="create-chair-form">
      <label for="startTime">Start Time</label>
      <p-inputMask
        class="mt-1"
        [required]="true"
        name="startTime"
        id="startTime"
        mask="99:99"
        placeholder="hh:mm"
        slotChar="0"
        [(ngModel)]="startTime"></p-inputMask>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" pButton label="Create" (click)="onConfirm()" class="btn btn-primary"></button>
    <button type="button" pButton label="Cancel" (click)="onCancel()" class="btn btn-default"></button>
  </div>
</div>
