import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { LeftNavigationComponent } from '../../shared/components/left-navigation/left-navigation.component';
import { GlobalSharedModule } from '../../shared/shared.module';
import { AddDailyNotesModalComponent } from '../components/add-daily-notes-modal/add-daily-notes-modal.component';
import { CopyScheduleModalComponent } from '../components/copy-schedule-modal/copy-schedule-modal.component';
import { ExportWarningModalComponent } from '../components/export-warning-modal/export-warning-modal.component';
import { IndirectScheduleModalComponent } from '../components/indirect-schedule-modal/indirect-schedule-modal.component';
import { PostScheduleComponent } from '../components/post-schedule-component/post-schedule.component';
import { PostScheduleMessageComponent } from '../components/post-schedule-message/post-schedule-message.component';
import { DayNotesService } from '../services/day-notes.service';
import { DateToStringWithoutHoursPipe } from '../shared/pipes/date-to-string-without-hours.pipe';
import { DollarValuePipe } from '../shared/pipes/dollar-value-pipe';
import { RoundValuePipe } from '../shared/pipes/round-value-pipe';
import { SharedModule } from './../../float-pool/shared/shared.module';
import { DatePickerModalModule } from './../../shared/components/date-picker-modal/date-picker-modal.module';
import { TopHeaderModule } from './../../shared/components/top-header/top-header.module';
import { ScheduleHelper } from './../shared/helper';
import { TwoWeekSelectionComponent } from './../shared/two-week-selection-component/two-week-selection';
import { ClearScheduleComponent } from './productivity/clear-schedule/clear-schedule.component';
import { ClearScheduleModalComponent } from './productivity/clear-schedule-modal/clear-schedule-modal.component';
import { FloatToolbarComponent } from './productivity/float-toolbar/float-toolbar.component';
import { ProductivityPanelComponent } from './productivity/productivity-panel.component';
import { PublishModalComponent } from './productivity/publish-modal/publish-modal.component';
import { ProductivityService } from './productivity/services/productivity.service';
import { SummarySectionComponent } from './productivity/summary-section/summary-section.component';
import { ScheduleWiseStaffComponent } from './schedule-wise-staff';
import { ShiftGridComponent } from './shift-grid-component/shift-grid-component';
import { ShiftInfoFormComponent } from './shift-info-form/shift-info-form';
import { StaffInfoComponentComponent } from './staff-info-component/staff-info-component.component';
import { StaffListComponent } from './staff-list-component/staff-list-component';
import { StaffTypeListComponent } from './staff-type-list-component/staff-type-list-component';
import { StaffTypeTooltipDirective } from './staff-type-tooltip.directive';

@NgModule({
  imports: [
    SharedModule,
    CalendarModule,
    TopHeaderModule,
    DatePickerModalModule,
    TooltipModule,
    RouterModule,
    DialogModule,
    DropdownModule,
    TableModule,
    OverlayPanelModule,
    MultiSelectModule,
    GlobalSharedModule,
    MenubarModule,
    InputSwitchModule,
    ToastModule,
    InputMaskModule,
    DynamicDialogModule,
  ],
  declarations: [
    ScheduleWiseStaffComponent,
    TwoWeekSelectionComponent,
    StaffTypeListComponent,
    StaffListComponent,
    LeftNavigationComponent,
    StaffTypeTooltipDirective,
    ShiftGridComponent,
    ShiftInfoFormComponent,
    ProductivityPanelComponent,
    SummarySectionComponent,
    PostScheduleComponent,
    StaffInfoComponentComponent,
    ShiftInfoFormComponent,
    PostScheduleMessageComponent,
    DollarValuePipe,
    RoundValuePipe,
    ExportWarningModalComponent,
    FloatToolbarComponent,
    PublishModalComponent,
    DateToStringWithoutHoursPipe,
    CopyScheduleModalComponent,
    IndirectScheduleModalComponent,
    AddDailyNotesModalComponent,
    ClearScheduleComponent,
    ClearScheduleModalComponent,
  ],
  providers: [ScheduleHelper, ProductivityService, DayNotesService],
})
export class ScheduleWiseStaffModule {}
