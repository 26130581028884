import { Pipe, PipeTransform } from '@angular/core';
import { convertMinutesToTimeString } from 'src/app/shared/helpers/dateTime';
@Pipe({
  name: 'minutesToTime',
})
export class MinutesToTimePipe implements PipeTransform {
  transform(value: number): string {
    return convertMinutesToTimeString(value);
  }
}
