<div class="side-bar">
  <div class="item" (click)="openPatients()" pTooltip="Patients"><i class="fa-solid fa-user"></i></div>
  <div class="item" (click)="openTransportation()" pTooltip="Transportation"><i class="fa-solid fa-bus"></i></div>
  <div class="item" (click)="openPhysicians()" pTooltip="Physicians"><i class="fa-solid fa-user-doctor"></i></div>
  <div class="item" (click)="openLegend()" pTooltip="Legend"><i class="fa-solid fa-key"></i></div>
  <div class="item" (click)="openContactUs()" pTooltip="Contact Us"><i class="fa-regular fa-envelope"></i></div>
  <a
    class="item"
    href="https://content.intranet.fmcna.com/schedulewise/"
    target="_blank"
    rel="noopener noreferrer"
    pTooltip="Support">
    <i class="fa-solid fa-address-card"></i>
  </a>
  <a
    class="item"
    href="https://content.intranet.fmcna.com/schedulewise/webinars/"
    target="_blank"
    rel="noopener noreferrer"
    pTooltip="Webinars">
    <i class="fa-solid fa-chalkboard-user"></i>
  </a>
</div>
