<div>
  <div class="patient-list">
    <p-table styleClass="p-datatable-striped" [scrollable]="true" scrollHeight="22rem" [value]="patients">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="PatientLastName">
            Name
            <p-sortIcon field="PatientLastName" />
          </th>
          <th pSortableColumn="MedicalRecordNumber">
            MRN
            <p-sortIcon field="MedicalRecordNumber" />
          </th>
          <th pSortableColumn="WeekDays">
            Days
            <p-sortIcon field="WeekDays" />
          </th>
          <th pSortableColumn="DurationInMinutes">
            Duration
            <p-sortIcon field="DurationInMinutes" />
          </th>
          <th>Add Patient</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr [ngClass]="['patient-row', patient.HepBStatus]">
          <td>{{ patient.PatientFirstName }} {{ patient.PatientLastName }}</td>
          <td>{{ patient.MedicalRecordNumber }}</td>
          <td>{{ patient.WeekDays }}</td>
          <td>{{ patient.DurationInMinutes | minutesToTime }}</td>
          <td class="button-col"><p-button icon="pi pi-plus" (onClick)="addPatient(patient)" /></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
