//import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TopHeaderModule } from 'src/app/shared/components/top-header/top-header.module';

import { GlobalSharedModule } from '../../shared/shared.module';
import { MinutesToTimePipe } from '../shared/pipes/minutes-to-time.pipe';
import { AddStaffShiftModalComponent } from './components/add-staff-shift-modal/add-staff-shift-modal.component';
import { ChairAvailabilityModalComponent } from './components/chair-availability-modal/chair-availability-modal.component';
import { CopyPatientScheduleModalComponent } from './components/copy-patient-schedule-modal/copy-patient-schedule-modal.component';
import { ExistingPatientModalComponent } from './components/existing-patient-modal/existing-patient-modal.component';
import { ExportScheduleModalComponent } from './components/export-modal/export.modal.component';
import { SchedulePatientGraphComponent } from './components/schedule-patient-graph/schedule-patient-graph.component';
import { SchedulePatientStaffShiftComponent } from './components/schedule-patient-staff-shift/schedule-patient-staff-shift.component';
import { SchedulePatientTreatmentComponent } from './components/schedule-patient-treatment/schedule-patient-treatment.component';
import { SharedChairEditModalComponent } from './components/shared-chair-edit-modal/shared-chair-edit-modal.component';
import { ScheduleWisePatientSidebarComponent } from './components/sidebar/sidebar.component';
import { ScheduleWisePatientComponent } from './schedule-wise-patient.component';

@NgModule({
  imports: [
    TopHeaderModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    CommonModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    MenuModule,
    MenubarModule,
    FormsModule,
    GlobalSharedModule,
    RadioButtonModule,
    RouterModule,
    SelectButtonModule,
    TableModule,
    TabMenuModule,
    ToggleButtonModule,
    ScheduleWisePatientSidebarComponent,
  ],
  declarations: [
    ScheduleWisePatientComponent,
    SchedulePatientTreatmentComponent,
    SchedulePatientStaffShiftComponent,
    SchedulePatientGraphComponent,
    CopyPatientScheduleModalComponent,
    ExportScheduleModalComponent,
    ExistingPatientModalComponent,
    SharedChairEditModalComponent,
    AddStaffShiftModalComponent,
    ChairAvailabilityModalComponent,
    MinutesToTimePipe,
  ],
})
export class ScheduleWisePatientModule {}
