import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { ICapacitySettingsData } from 'src/app/virtual-lobby/models/capacity-settings';
import { IPatient } from 'src/app/virtual-lobby/models/patient';

import { DiseaseService } from '../core/services/disease.service';
import { IClinicShedule } from '../core/services/swac-clinic.service';
import { IPatientByZone } from '../models';

@Injectable()
export class PatientsService {
  filtersState: BehaviorSubject<any>;
  patientInfo: BehaviorSubject<any>;
  currentZone: BehaviorSubject<string>;
  zoneArray: string[];
  reverseZoneArray: string[];

  constructor(private api: ApiService, private messageService: MessageService, private diseaseService: DiseaseService) {
    this.filtersState = new BehaviorSubject({});
    this.patientInfo = new BehaviorSubject({});
    this.currentZone = new BehaviorSubject('--');

    this.zoneArray = ['division', 'group', 'region', 'area', 'clinic'];
    this.reverseZoneArray = [...this.zoneArray].reverse();
  }

  searchByMrn(mrn: string): Observable<{ patient: IPatient }> {
    return this.api.makeRequest('get', `patients/${mrn}`, {
      showIndicator: true,
      ignoreErrors: true,
    });
  }

  getFiltersStateSubject() {
    return this.filtersState;
  }

  getPatientInfoSubject() {
    return this.patientInfo;
  }

  getCurrentZoneSubject() {
    return this.currentZone;
  }

  getZoneArray() {
    return this.zoneArray;
  }

  getReverseZoneArray() {
    return this.reverseZoneArray;
  }

  getPatientsByZone(zone, zoneId, transient = false) {
    return this.api.makeRequest<IPatientByZone>('get', `swac/${zone}/${zoneId}/patients`, {
      queryParams: transient ? new HttpParams().set('patientTypeId', '2') : null,
    });
  }

  getPatient(id) {
    return this.api.makeRequest('get', `swac/patients/${id}`).pipe(map((res: any) => res.patient));
  }

  getAnyZones(child, parent = null, parentId = null) {
    return this.api.makeRequest('get', parent ? `swac/${parent}s/${parentId}/${child}s` : `swac/${child}s`).pipe(
      map((res) => {
        return {
          child,
          zones: res[`${child}s`],
        };
      }),
    );
  }

  private massageTransientOutbound(patient, isEdit = false) {
    if (patient && patient.transient) {
      const t = patient.transient;
      // massage object to match api schema
      t.durationInMinutes = t.durationHours * 60 + t.durationMinutes;
      delete t.durationHours;
      delete t.durationMinutes;
      t.numberOfTreatments = parseInt(t.numberOfTreatments, 10);
      if (t.idHomeCenter) t.idHomeCenter = t.idHomeCenter.value;

      if (isEdit && t.endDate === null) {
        t.endDate = '1900-01-01';
      }
    }
    return patient;
  }

  massageTransientInbound(patient) {
    if (patient && patient.transient) {
      const t = patient.transient;
      t.durationHours = Math.floor(t.durationInMinutes / 60);
      t.durationMinutes = t.durationInMinutes % 60;
    }
    return patient;
  }

  private massageHepBOutbound(patient) {
    const status = [];
    if (patient) {
      // If hepBPositive is true here, the checkbox for postive is checked, so send the positive status
      if (patient.hepBPositive) {
        status.push('4');
      } else if (!patient.hepBPositve && patient.currentHepStatus === '4') {
        // User has unchecked the box of a previously positive patient
        status.push('1');
      } else if (patient.currentHepStatus) {
        // Otherwise, send the current hep status (1-3) so as to not overwrite it
        status.push(patient.currentHepStatus);
      } else {
        // If its not there, push 1 for unknown.
        status.push('1');
      }

      // If patientDiseaseStatus is set (currenty for COVID-19) push that on the array.
      if (patient.patientDiseaseStatus) {
        status.push(patient.patientDiseaseStatus);
      }
      patient.patientDiseaseStatus = status.join(',');
      delete patient.hepBPositive;
      delete patient.currentHepStatus;
      return patient;
    }
  }

  massageHepBInbound(patient) {
    if (patient && patient.patientDiseaseStatusId) {
      const statuses = patient.patientDiseaseStatusId.split(',');
      if (statuses.length) {
        statuses.forEach((status) => {
          if (this.diseaseService.isDiseaseStatus('hep', status)) {
            patient.hepStatusId = status;
            if (status === '4') {
              patient.hepBPositive = true;
            }
          } else if (this.diseaseService.isDiseaseStatus('covid', status)) {
            patient.patientDiseaseStatus = status;
          }
        });
      }
      return patient;
    }
  }

  postPatient(patient) {
    patient = this.massageTransientOutbound(patient);
    patient = this.massageHepBOutbound(patient);
    return this.api.makeRequest('post', 'swac/patients', {
      payload: { patient },
      errorMessage: 'Could not create patient',
    });
  }

  patchPatient(patientLobbyId, patient) {
    patient = this.massageTransientOutbound(patient, true);
    patient = this.massageHepBOutbound(patient);
    if (!patient.statusId) delete patient.statusId;
    return this.api.makeRequest('patch', `swac/patients/${patientLobbyId}`, {
      payload: patient,
      errorMessage: 'Could not update patient',
    });
  }

  getClinicsByNameOrId(query) {
    return this.api
      .makeRequest('get', 'swac/clinics', {
        queryParams: new HttpParams().append('q', query),
        errorMessage: 'Could not complete query',
        showIndicator: false,
      })
      .pipe(map((res: any) => res.clinics));
  }

  getClinicByIdCenter(idCenter) {
    return this.api.makeRequest<IGetClinicResponse>('get', `swac/clinics/${idCenter}`).pipe(map((res) => res.clinic));
  }
}
export interface IGetClinicResponse {
  clinic: IGetClinic;
}

export interface IGetClinic {
  areaId: number;
  atCapacityStatus: number;
  clinicCapabilities: string;
  clinicDays: string;
  clinicId: string;
  clinicName: string;
  dateModified: string;
  divisionId: number;
  groupId: number;
  idCenter: number;
  name: string;
  notes: string;
  numberOfIsoChairs: number;
  numberOfLicensedStations: number;
  numberOfNonIsoChairs: number;
  phoneNumber: string;
  regionId: number;
  scheduleSets: { [key: string]: IClinicShedule[] };
  schedules: IClinicShedule[];
  virtualLobbySettings: ICapacitySettingsData;
}
