<div
  pDraggable
  class="treatment"
  [ngClass]="[
    treatment?.HepBStatus || '',
    closed ? 'closed' : '',
    open ? 'open' : '',
    treatment?.PatientLobbyId ? 'open' : '',
    transient ? 'transient' : '',
    showCompare ? 'compare-mode' : '',
    !wizardMode ? preTurnoverClass : '',
    !wizardMode ? postTurnoverClass : '',
    isCurrentDragTarget ? 'highlight-blue' : ''
  ]"
  (dragstart)="onDragStart($event, treatment)"
  (dragleave)="onDragLeave($event)"
  (dragover)="onDragOver($event, treatment)"
  (drop)="onDrop($event, treatment)"
  (dragend)="onDragEnd()">
  <div *ngIf="treatments.length > 1 && !closed" class="shared-chairs">
    <div
      *ngFor="let tx of treatments; index as i"
      (click)="changeSharedChair(i)"
      [ngClass]="['chair-tab', tx.HepBStatus || '', selectedSharedChair === i ? 'selected-chair' : '']">
      <i *ngIf="selectedSharedChair === i" class="selected-chair-icon pi pi-circle-fill"></i>
      <span>{{ dayLetters[treatments[i].SharedChairDay - 1] }}</span>
    </div>
  </div>
  <div class="treatment-content">
    <div
      [ngClass]="[
        'top-row',
        empty ? 'empty' : '',
        emptySharedChairSlot ? 'empty-shared-chair' : '',
        !wizardMode ? preTurnoverClass : '',
        !wizardMode ? postTurnoverClass : ''
      ]">
      <div *ngIf="empty && !emptySharedChairSlot" class="empty top-row-info">
        <i class="pi pi-exclamation-triangle"></i>
        Action required.
      </div>
      <div *ngIf="empty && emptySharedChairSlot && sharedChairEditMode" class="empty top-row-info">
        Drag a patient here.
      </div>
      <div *ngIf="!empty && !emptySharedChairSlot" class="top-row-info">
        <div *ngIf="treatment.LastName" class="name">
          <span (mouseenter)="showPopover()" (mouseleave)="hidePopover()" [class]="{ 'high-acuity': highAcuity }">
            {{ treatment.LastName }}, {{ treatment.FirstName }}
          </span>
        </div>
        <div *ngIf="treatment.LastName" class="duration">{{ durationDisplay }}</div>
        <div *ngIf="!treatment.LastName" class="name">
          {{ closed ? 'Unavailable' : open ? 'Open chair' : treatment.ChairStatus }}
        </div>
      </div>
      <div
        *ngIf="!sharedChairEditMode && !wizardMode && menuItems?.length"
        class="menu-toggle"
        (click)="menu.toggle($event)">
        <p-menu #menu appendTo="body" [model]="menuItems" [popup]="true" />
        <i #target class="pi pi-caret-down"></i>
      </div>
    </div>
    <div *ngIf="!empty && !closed" class="bottom-row">
      <p-inputMask
        type="text"
        mask="99:9a"
        characterPattern="[05]"
        placeholder="hh:mm"
        slotChar="hh:mm"
        [disabled]="wizardMode"
        [ngClass]="['start-time', !wizardMode ? preTurnoverClass : '', invalidStart ? 'invalid-time' : '']"
        [(ngModel)]="startTime"
        (onBlur)="onStartTimeChange()" />
      <div [ngClass]="['end-time', !wizardMode ? postTurnoverClass : '']">{{ endTime }}</div>
      <div *ngIf="invalidStart" class="invalid-label">Invalid start time</div>
      <div class="needs-container" *ngIf="!compareMode">
        <img
          *ngFor="let need of treatment.PatientNeeds"
          [ngClass]="['need-icon', need]"
          src="{{ '/client/assets/img/' + need + '.jpg' }}" />
      </div>
      <div class="compare-data" *ngIf="compareMode && compareDisplay">
        <div class="compare-item">
          {{ compareDisplay }}
        </div>
      </div>
    </div>
    <div *ngIf="empty || closed" [ngClass]="['bottom-row', emptySharedChairSlot ? 'empty-shared-chair' : '']">
      <div *ngIf="empty && !emptySharedChairSlot" class="status-reason">
        Open this chair or change it to 'Unavailable.'
      </div>
      <div *ngIf="!empty" class="status-reason">{{ treatment.StatusReason }}</div>
    </div>
  </div>

  <!-- Custom popover -->
  <div *ngIf="popoverVisible && !showCompare" class="detail-overlay">
    <div class="caret"></div>
    <div class="overlay-container">
      <div>
        <b>{{ treatment.FirstName }} {{ treatment.LastName }}</b>
      </div>
      <div>
        Acuity on:
        <b>{{ treatment.AcuityOnPre }}/{{ treatment.AcuityOnPost }}</b>
      </div>
      <div>
        Acuity off:
        <b>{{ treatment.AcuityOffPre }}/{{ treatment.AcuityOffPost }}</b>
      </div>
      <div *ngIf="!wizardMode">
        Turnovers:
        <b>{{ treatment.PreTurnover || '--' }}/{{ treatment.PostTurnover || '--' }}</b>
      </div>
      <div *ngIf="preTurnoverMessage && !wizardMode" class="turnover-message">{{ preTurnoverMessage }}</div>
      <div *ngIf="postTurnoverMessage && !wizardMode" class="turnover-message">{{ postTurnoverMessage }}</div>
    </div>
    <div [ngClass]="['hep-status', treatment.HepBStatus || '']">HepB - {{ treatment.HepBStatus || '' }}</div>
  </div>
</div>
<chair-availability-modal
  *ngIf="showChairAvailabilityModal"
  [data]="chairAvailabilityData"
  (hideModal)="closeModal($event)"></chair-availability-modal>
