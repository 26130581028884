export interface IDisease {
  PatientDiseaseStatus_Id: number;
  PatientDiseaseStatus: string;
  CSSDisplay: string;
}

export const hepOptions: IDisease[] = [
  {
    PatientDiseaseStatus_Id: 1,
    PatientDiseaseStatus: 'Unknown',
    CSSDisplay: 'Unknown',
  },
  {
    PatientDiseaseStatus_Id: 2,
    PatientDiseaseStatus: 'Immune',
    CSSDisplay: 'Antibody_Positive',
  },
  {
    PatientDiseaseStatus_Id: 3,
    PatientDiseaseStatus: 'Susceptible',
    CSSDisplay: 'Susceptible',
  },
  {
    PatientDiseaseStatus_Id: 4,
    PatientDiseaseStatus: 'Positive',
    CSSDisplay: 'HepB_Positive',
  },
];
export const covidOptions: IDisease[] = [
  {
    PatientDiseaseStatus_Id: 5,
    PatientDiseaseStatus: 'Close Contact Exposure',
    CSSDisplay: 'Close Contact Exposure',
  },
  {
    PatientDiseaseStatus_Id: 6,
    PatientDiseaseStatus: 'PUI',
    CSSDisplay: 'PUI',
  },
  {
    PatientDiseaseStatus_Id: 7,
    PatientDiseaseStatus: 'COVID-19 Positive',
    CSSDisplay: 'COVID-19 Positive',
  },
  {
    PatientDiseaseStatus_Id: 8,
    PatientDiseaseStatus: 'N/A',
    CSSDisplay: 'N/A',
  },
  {
    PatientDiseaseStatus_Id: 11,
    PatientDiseaseStatus: 'Recovered',
    CSSDisplay: 'Recovered',
  },
  {
    PatientDiseaseStatus_Id: 12,
    PatientDiseaseStatus: 'Resolved',
    CSSDisplay: 'Resolved',
  },
];

export interface IDiseases {
  hep: IDisease[];
  covid: IDisease[];
}

export interface IPatientResponse {
  patient: IPatient;
}

export interface IPatient {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  medicalRecordNumber: string;
  dob: string;
  hep: string;
  modality: string;
  covid19StatusId: covidStatusId;
}

export enum covidStatusId {
  'Close Contact Exposure' = 5,
  'COVID-19 Positive' = 7,
  'PUI' = 6,
  'Recovered' = 1,
  'Resolved' = 2,
  'N/A' = 8,
}

export interface IPatientUploadData {
  idPatientMaster?: number;
  treatments?: number;
  notes?: string;
  hep?: number;
  patientType?: number;
  startDate?: string;
  covidStatus?: number;
  endDate?: string;
  hour?: number;
  minute?: number;

  // these get added after and are not part of the patient
  patientLobbyId?: number;
  scheduleId?: string;

  patientId?: number;
  legacyIdCenter?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  mrn?: string;
}

export interface IPatientUploadRequest {
  notes?: string;
  startDate?: string;
  patientTypeId?: string;
  endDate?: string;
  numberOfTreatments?: string;
  durationInMinutes?: string;
  idHomeCenter?: string;
  acuityOnPre_Minutes?: string;
  acuityOnPost_Minutes?: string;
  acuityOffPre_Minutes?: string;
  acuityOffPost_Minutes?: string;
  scheduleId?: string;
  idCenter?: string;
  patientDiseaseStatusId?: string;
  IdPatientMaster?: string;
}

export interface IVirtualLobbyData {
  lessThanTwoWeeks: number;
  moreThanTwoWeeks: number;
  inLobby: number;
  placed: number;
  removed: number;
  scheduled: number;
}
export interface IVLPatientData {
  patientLobbyId: number;
  patientTypeId: number;
  status: 'PLACED' | 'IN_LOBBY';
  firstName: string;
  middleName: string;
  lastName: string;
  mrn: string;
  dob: string;
  statusId: 1 | 2;
  startDate: string;
  scheduleId: string;
  startTime: string;
  endTime: string;
  clinicId: string;
  notes: string;
  podId: number;
  podName: string;
  chairId: number;
  chairName: string;
  shiftNumber: number;
  idCenter: number;
  patientId: number;
  scheduledDays: string;
  endDate: string;
  numberOfTreatments: number;
  durationInMinutes: number;
  idHomeCenter: null;
  patientDiseaseStatusId: string;
  homeClinicName: null;
  idHomeClinic: null;
  acuityOnPre_Minutes: null;
  acuityOnPost_Minutes: null;
  acuityOffPre_Minutes: null;
  acuityOffPost_Minutes: null;
  IdPatientMaster: number;
  VirtualLobbyPatientId: number;
  IsEcubeCovid19Status: boolean;
  IsEcubeDuration: number;
  IsEcubeHepStatus: boolean;

  iso?: boolean;
  displayTime?: string;
}

export interface IVlModified {
  DateModified: string;
  FirstName: string;
  LastName: string;
  ModifiedBy_IdUsers: number;
}

export interface IGetVirtualLobbyOccupancyResponse {
  modified: IVlModified;
  lobbyData: IVirtualLobbyData;
  patientData: IVLPatientData[];
}

export enum patientTypesEnum {
  'Permanent (ESRD)' = 1,
  'Transient' = 2,
  'Transfer' = 3,
  'AKI (non-ESRD)' = 4,
  'Disaster' = 5,
}

export interface IPDropdownOptions {
  value: string | number;
  label: string;
}
