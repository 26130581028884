import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../../../../shared/services/api.service';
import { IChairReasonsResponse } from '../../models/chair-availability-modal';

@Injectable({
  providedIn: 'root',
})
export class ChairAvailabilityModalService {
  constructor(private apiService: ApiService) {}

  public getReasons(): Observable<IChairReasonsResponse> {
    return this.apiService.makeRequest<IChairReasonsResponse>('GET', `patients/get-reasons`);
  }

  public saveChair(
    eventScheduleId: number,
    centerId: number,
    chairId: number,
    scheduleShift: number,
    scheduleEventStatus_LC: string,
    reasonDescription_LC: string,
    note?: string,
  ): Observable<any> {
    return this.apiService
      .makeRequest<void>('POST', `patients/save-chair`, {
        payload: {
          eventScheduleId,
          centerId,
          chairId,
          scheduleShift,
          scheduleEventStatus_LC,
          reasonDescription_LC,
          note,
        },
      })
      .pipe(
        map((response) => response),
        catchError((err) => {
          throw err;
        }),
      );
  }
}
