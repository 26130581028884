import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { IPatientsModalResponse } from '../../models/patient-modal';
import { DEFAULT_ACCUITIES, IPatientTreatmentEvent } from '../../models/schedule-patient.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  standalone: false,
  selector: 'existing-patient-modal',
  templateUrl: './existing-patient-modal.component.html',
  styleUrls: ['./existing-patient-modal.component.scss'],
  providers: [DialogService],
})
export class ExistingPatientModalComponent implements OnInit {
  public patients: IPatientsModalResponse[];
  public treatment: IPatientTreatmentEvent;
  public treatments: IPatientTreatmentEvent[];
  public legacyIdCenter: number;

  constructor(
    private config: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private patientsModalService: PatientsModalService,
    private patientScheduleService: PatientScheduleService,
  ) {}

  ngOnInit(): void {
    this.legacyIdCenter = this.config.data.legacyIdCenter;
    this.treatment = this.config.data.treatment;
    this.patientsModalService.getPatients(this.legacyIdCenter).subscribe((res) => {
      this.patients = res;
    });
  }

  public addPatient(patient: IPatientsModalResponse): void {
    if (patient.WeekDays?.length) {
      this.removePatient(patient);
    } else {
      this.addPatientToTreatment(patient, this.treatment);
    }
  }

  private removePatient(patient: IPatientsModalResponse): void {
    this.confirmationService.confirm({
      message: `You need to remove this patient's existing schedule. Would you like to do that now?`,
      header: 'Confirm Removal',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      accept: () => {
        // Close old treatments
        this.patientScheduleService.closeTreatmentsForPatient(patient.IdPatient).subscribe((resClose) => {
          if (resClose.statusCode === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'Remove Patient',
              detail: `The patient's previous treatments have been removed.`,
            });
            // Create new treatment
            this.closeTreatmentAndAddPatient(patient, this.treatment);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Patient Error',
              detail: `There was an error removing this patient's previous treatments.`,
            });
          }
        });
      },
    });
  }

  private addPatientToTreatment(patient: IPatientsModalResponse, tx: IPatientTreatmentEvent): void {
    this.confirmationService.confirm({
      message: `Do you want to add ${patient.PatientFirstName} ${patient.PatientLastName} to this chair?`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Add Patient',
      rejectLabel: 'Cancel',
      accept: () => {
        this.closeTreatmentAndAddPatient(patient, tx);
      },
    });
  }

  private closeTreatmentAndAddPatient(patient: IPatientsModalResponse, tx: IPatientTreatmentEvent): void {
    this.patientScheduleService
      .closeTreatmentEvent(tx.IdEventSchedule, tx.ChairId, tx.ShiftNumber)
      .subscribe((resClose) => {
        if (resClose?.statusCode === 200) {
          this.patientScheduleService
            .createTreatmentEvent(
              this.legacyIdCenter,
              tx.IdEventSchedule,
              tx.ChairId,
              tx.ShiftNumber,
              tx.StartTime || '00:00',
              patient.DurationInMinutes,
              patient.IdPatient,
              DEFAULT_ACCUITIES.AcuityOnPre,
              DEFAULT_ACCUITIES.AcuityOffPre,
              DEFAULT_ACCUITIES.AcuityOnPost,
              DEFAULT_ACCUITIES.AcuityOffPost,
            )
            .subscribe((resCreate) => {
              if (resCreate?.length && resCreate[0].SuccessCode) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Patient Added',
                  detail: 'Patient was added to this chair.',
                });
                this.close(true);
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Patient Error',
                  detail: 'There was an error adding this patient to the schedule.',
                });
              }
            });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Patient Error',
            detail: 'There was an error with this chair.',
          });
        }
      });
  }

  private close(refresh: boolean): void {
    this.ref.close(refresh);
  }
}
