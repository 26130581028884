<div class="body">
  <form #addPatientForm="ngForm" (ngSubmit)="formSubmit(addPatientForm)">
    <div *ngIf="showMrn">
      <div class="flex my-3">
        <label class="font-bold" for="selMrn" class="flex align-items-center mr-2">
          Lookup a patient by Medical Record Number:
        </label>
        <input
          class="flex-1 mr-2 text-center"
          type="text"
          name="selMrn"
          id="selMrn"
          [(ngModel)]="mrn"
          placeholder="Enter MRN (3-15 characters)"
          pInputText />
        <p-button [disabled]="mrn?.length < 3" (click)="lookupMRN()">Look Up</p-button>
      </div>
    </div>
    <div *ngIf="showOpenChairs" class="flex my-3 align-items-center flex-column justify-content-center">
      <div class="flex">
        <label for="selChair" class="flex align-items-center mr-2 font-bold">
          Open Chairs
          <span class="required">*</span>
          :
        </label>
        <p-dropdown
          name="selChair"
          id="selChair"
          appendTo="body"
          [options]="openChairsOptions"
          [(ngModel)]="patientUploadData.scheduleId"
          placeholder="Choose One"
          [required]="true"></p-dropdown>
      </div>

      <small class="setting-error my-1" *ngIf="openChairsOptions.length === 0">
        There are no open chairs available.
      </small>
      <small
        class="setting-error my-1"
        *ngIf="
          openChairsOptions.length > 0 &&
          addPatientForm.controls.selChair?.invalid &&
          (addPatientForm.controls.selChair?.touched || addPatientForm.submitted)
        ">
        You must select an open chair.
      </small>
      <small
        class="chair-error my-3 p-5"
        *ngIf="openChairs?.[patientUploadData?.scheduleId]?.stationTypeLC !== 'ISO' && patientUploadData.hep === 4">
        You're attempting to place a Hepatitis B positive patient on a non - iso chair. Are you sure you want to do
        this?
      </small>
      <small
        class="chair-error my-3 p-5"
        *ngIf="openChairs?.[patientUploadData?.scheduleId]?.stationTypeLC === 'BUFFER' &&  patientUploadData.hep !== 4">
        You're attempting to place a patient on a Buffer Chair. Are you sure you want to do this?
      </small>
      <small
        class="chair-error my-3 p-5"
        *ngIf="openChairs?.[patientUploadData?.scheduleId]?.stationTypeLC === 'ISO' &&  patientUploadData.hep !== 4">
        You're attempting to place a patient on an ISO Chair. Are you sure you want to do this?
      </small>
    </div>
    <div class="">
      <div class="grid">
        <div class="col-4 flex flex-column align-items-center">
          <div class="font-bold">Patient Name</div>
          <div class="mt-1">{{ fullName || '--' }}</div>
        </div>
        <div class="col-4 flex flex-column align-items-center">
          <div class="font-bold">Medical Record Number</div>
          <div class="mt-1">{{ medicalRecordNumber || '--' }}</div>
        </div>
        <div class="col-4 flex flex-column align-items-center">
          <div class="font-bold">Date of Birth</div>
          <div class="mt-1">{{ dateOfBirth || '--' }}</div>
        </div>
      </div>
      <div class="grid">
        <div class="col-4 my-2 flex flex-column align-items-center">
          <label class="font-bold" for="selHep">
            Hepatitis Status
            <span class="required">*</span>
          </label>
          <p-dropdown
            class="mt-1 hep-status"
            name="selHep"
            id="selHep"
            appendTo="body"
            [options]="hepOptions"
            optionValue="PatientDiseaseStatus_Id"
            optionLabel="PatientDiseaseStatus"
            [(ngModel)]="patientUploadData.hep"
            placeholder="Choose One"
            [disabled]="hepDisabled"
            [required]="!hepDisabled"></p-dropdown>
          <small
            class="setting-error my-1"
            *ngIf="
              addPatientForm.controls.selHep?.invalid &&
              (addPatientForm.controls.selHep?.touched || addPatientForm.submitted)
            ">
            A hepatitis status is required.
          </small>
        </div>
        <div class="col-4 my-2 flex flex-column align-items-center">
          <label class="font-bold" for="selPatientType">
            Patient Type
            <span class="required">*</span>
          </label>
          <p-dropdown
            class="mt-1 patient-type"
            name="selPatientType"
            id="selPatientType"
            appendTo="body"
            (onChange)="resetTransient()"
            [options]="patientTypeOptions"
            [(ngModel)]="patientUploadData.patientType"
            placeholder="Choose One"
            [required]="true"></p-dropdown>
          <small
            class="setting-error my-1"
            *ngIf="
              addPatientForm.controls.selPatientType?.invalid &&
              (addPatientForm.controls.selPatientType?.touched || addPatientForm.submitted)
            ">
            A patient type is required.
          </small>
        </div>
        <div class="col-4 my-2 flex flex-column align-items-center">
          <label class="font-bold" for="selStartDate">
            Start Date
            <span class="required">*</span>
          </label>
          <p-calendar
            class="mt-1"
            name="selStartDate"
            id="selStartDate"
            appendTo="body"
            dataType="string"
            [minDate]="todayDate"
            [required]="true"
            placeholder="MM/DD/YYYY"
            [(ngModel)]="patientUploadData.startDate"></p-calendar>
          <small
            class="setting-error my-1"
            *ngIf="
              addPatientForm.controls.selStartDate?.invalid &&
              (addPatientForm.controls.selStartDate?.touched || addPatientForm.submitted)
            ">
            Start Date is required.
          </small>
        </div>

        <ng-container *ngIf="patientUploadData?.patientType === 2">
          <div class="col-4 my-2 flex flex-column align-items-center">
            <label class="font-bold" for="selPatientType">End Date</label>
            <p-calendar
              class="mt-1"
              name="selEndDate"
              id="selEndDate"
              appendTo="body"
              dataType="string"
              [minDate]="todayDate"
              placeholder="MM/DD/YYYY"
              [(ngModel)]="patientUploadData.endDate"></p-calendar>
          </div>
          <div class="col-4 my-2 flex flex-column align-items-center">
            <label class="font-bold" for="selPatientType">
              Duration
              <span class="required">*</span>
            </label>
            <div class="mt-1">
              <p-dropdown
                class="ml-2 duration"
                name="selHour"
                id="selHour"
                appendTo="body"
                [disabled]="ISECUBEDURATION === 1"
                [options]="hourOptions"
                [(ngModel)]="patientUploadData.hour"
                [required]="true"
                placeholder="Hr"></p-dropdown>
              <p-dropdown
                class="ml-2 duration"
                name="selMinute"
                id="selMinute"
                appendTo="body"
                [disabled]="ISECUBEDURATION === 1"
                [options]="minuteOptions"
                [(ngModel)]="patientUploadData.minute"
                [required]="true"
                placeholder="Min"></p-dropdown>
            </div>
            <small
              class="setting-error my-1"
              *ngIf="
                ((addPatientForm.controls.selHour?.invalid || addPatientForm.controls.selMinute?.invalid) &&
                  ((addPatientForm.controls.selHour?.touched && addPatientForm.controls.selMinute?.touched) ||
                    addPatientForm.submitted)) ||
                (patientUploadData.hour === 0 && patientUploadData.minute === 0)
              ">
              Hour and minute are required.
            </small>
          </div>
          <div class="col-4 my-2 flex flex-column align-items-center">
            <label class="font-bold" for="selNumTreatments">
              Number of Treatments
              <span class="required">*</span>
            </label>
            <input
              type="number"
              name="selNumTreatments"
              id="selNumTreatments"
              class="text-center mt-1"
              [(ngModel)]="patientUploadData.treatments"
              maxlength="3"
              min="1"
              [required]="true"
              pInputText />
            <small
              class="setting-error my-1"
              *ngIf="
                addPatientForm.controls.selNumTreatments?.invalid &&
                (addPatientForm.controls.selNumTreatments?.touched || addPatientForm.submitted)
              ">
              Number of treatments is required.
            </small>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-column">
      <label class="font-bold" for="selNotes">General Notes</label>
      <textarea
        class="width-100 mt-1"
        name="selNotes"
        id="selNotes"
        [rows]="3"
        maxlength="256"
        pInputTextarea
        [autoResize]="true"
        [(ngModel)]="patientUploadData.notes"></textarea>
    </div>
    <div class="flex justify-content-end no-wrap mt-3">
      <p-button (click)="dismissDialog()">Cancel</p-button>
      <button type="submit" pButton class="ml-3 btn btn-default">Submit</button>
    </div>
  </form>
</div>

<p-dialog
  [(visible)]="showHepWarning"
  [draggable]="false"
  [resizable]="false"
  [dismissableMask]="true"
  header="Hep Status Warning">
  The patient you are adding to your clinic is Hepatitis B positive.
  <ng-template pTemplate="footer">
    <p-button (click)="showHepWarning = false">Ok</p-button>
  </ng-template>
</p-dialog>
