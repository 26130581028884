import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { chairTypes, zoneTypes } from '../../../../podconfig/shared/podconfig.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { CreateOpenChairModalComponent } from '../create-open-chair-modal/create-open-chair-modal.component';
import { ChairAvailabilityModalService } from './chair-availability-modal.service';

@Component({
  selector: 'chair-availability-modal',
  templateUrl: './chair-availability-modal.component.html',
  styleUrls: ['./chair-availability-modal.component.scss'],
  providers: [DialogService],
})
export class ChairAvailabilityModalComponent implements OnInit {
  @Input() data: any;
  @Output() hideModal = new EventEmitter<any>();
  title: string = 'Chair Availability';
  displayModal: boolean = true;
  chairStatuses: any[] = [];
  reasonDescriptions: any[] = [];
  chairId: number;
  chairStatus: string = '';
  reasonCode: string = '';
  additionalInfo: string = '';
  podShiftChairDisplay: string = '';
  statusReason: string = '';
  submitPressed: boolean = false;
  stationType: { display: string } | null = null;
  stationZone: { display: string } | null = null;

  constructor(
    private chairAvailabilityModalService: ChairAvailabilityModalService,
    private patientScheduleService: PatientScheduleService,
    private dialogService: DialogService,
    private ms: MessageService,
  ) {}

  ngOnInit(): void {
    this.loadChairReasons();
  }

  private loadChairReasons(): void {
    this.chairAvailabilityModalService.getReasons().subscribe((response) => {
      this.processChairReasons(response.reasons);
      this.initializeData();
    });
  }

  private initializeData(): void {
    const chairData = this.data || {};
    this.chairId = chairData.chairId;
    this.chairStatus = chairData.chairStatus || '';
    this.additionalInfo = chairData.additionalInfo || '';
    this.reasonCode = this.getReasonCodeFromDescription(chairData.statusReason);
    this.podShiftChairDisplay = `${chairData.podName || ''} - Chair ${chairData.chairName || ''} - Shift ${
      chairData.shiftNumber || ''
    }`;

    // Find the pod containing the chair with the given chairId (legacyId)
    const matchingPod = this.data?.podConfig?.find((pod) =>
      pod.chairs?.some((chair) => chair.legacyId === this.chairId),
    );

    if (matchingPod) {
      // Find the specific chair within the pod
      const matchingChair = matchingPod.chairs?.find((chair) => chair.legacyId === this.chairId);

      if (matchingChair) {
        // Map the chair type using the 'type' field in the chair data
        const chairType = chairTypes.find((type) => type.id === matchingChair.type);
        this.stationType = { display: chairType?.name || 'Unknown' };

        // Map the zone type using the pod's zone field
        const podZoneType = zoneTypes.find((zone) => zone.id === matchingPod.zone);
        this.stationZone = { display: podZoneType?.name || 'Unknown' };
      }
    } else {
      this.stationType = { display: 'Unknown' };
      this.stationZone = { display: 'Unknown' };
    }
  }

  private getReasonCodeFromDescription(statusReason: string): string {
    if (!statusReason || !this.reasonDescriptions) return '';
    const matchedReason = this.reasonDescriptions.find((reason) => reason.LookUpValue === statusReason);
    return matchedReason ? matchedReason.LC : '';
  }

  private processChairReasons(reasons: any[]): void {
    this.chairStatuses = reasons.filter((reason) => reason.LookUpGroup_LG === 'REASONCODE');
    this.reasonDescriptions = reasons.filter((reason) => reason.LookUpGroup_LG === 'REASONDESC');
  }

  isReasonRequired(): boolean {
    return this.chairStatus !== 'AVAILABLE';
  }

  onChairStatusChange(): void {
    if (this.chairStatus === 'AVAILABLE') {
      this.reasonCode = '';
    }
  }

  submitForm(): void {
    this.submitPressed = true;
    if (this.isReasonRequired() && !this.reasonCode) {
      return;
    }

    if (this.chairStatus === 'AVAILABLE') {
      this.displayModal = false;
      this.showOpenChairDialog();
    } else {
      this.saveChair();
    }
  }

  private showOpenChairDialog(): void {
    const ref = this.dialogService.open(CreateOpenChairModalComponent, {
      header: 'Enter Start Time for Open Chair',
      width: '400px',
      closable: true,
      data: {
        label: 'Start Time',
        button: 'Create',
      },
    });

    ref.onClose.subscribe((result) => {
      if (result?.startTime) {
        this.createOpenChair(result.startTime);
      } else {
        this.displayModal = true;
      }
    });
  }

  private saveChair(): void {
    this.chairAvailabilityModalService
      .saveChair(
        this.data.eventScheduleID,
        this.data.centerId,
        this.chairId,
        this.data.shiftNumber,
        this.chairStatus,
        this.reasonCode,
        this.additionalInfo,
      )
      .subscribe(
        (response) => {
          this.ms.add({ severity: 'success', detail: 'Changes saved successfully.' });
          this.hideModal.emit(response);
          this.closeModal();
        },
        () => {
          this.hideModal.emit(null);
          this.ms.add({ severity: 'error', detail: 'Failed to save the chair.' });
        },
      );
  }

  private createOpenChair(startTime: string, duration?: number): void {
    this.patientScheduleService
      .createOpenChair(this.data.eventScheduleID, this.chairId, this.data.shiftNumber, startTime, duration)
      .subscribe(() => {
        this.saveChair();
      });
  }

  closeModal(): void {
    this.displayModal = false;
    this.hideModal.emit(null);
  }
}
