import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';

@Component({
  selector: 'app-create-open-chair-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, DialogModule, ButtonModule, InputMaskModule],
  templateUrl: './create-open-chair-modal.component.html',
  styleUrls: ['./create-open-chair-modal.component.scss'],
})
export class CreateOpenChairModalComponent {
  displayModal: boolean = true;
  startTime: string = '';

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  onConfirm(): void {
    this.ref.close({ startTime: this.startTime });
  }

  onCancel(): void {
    this.ref.close(null);
  }
}
