import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { map, of, Subscription } from 'rxjs';
import { ClinicDetail } from 'src/app/dashboard/shared/clinic.models';
import { AdminService } from 'src/app/float-pool/admin/admin.service';
import { VirtualLobbyService } from 'src/app/virtual-lobby/services/virtual-lobby.service';

import { environment } from '../../../../environments/environment';
import { ProviderService } from '../../../schedule-wise/services/provider.service';
import { ClinicService } from '../../services/clinic.service';
import { FloatService } from '../../services/float.service';
import { SessionService, UserLogin } from '../../services/session.service';

@Component({
  selector: 'upper-menu-component',
  templateUrl: './upper-menu.html',
  styleUrls: ['./upper-menu-component.scss', '../../../../styles/upper-menu.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpperMenuComponent implements OnInit, OnDestroy {
  floatUser: boolean;
  userLogin = '--';
  navLinks: INavLink[];
  swUri = '';
  selectedClinic: ClinicDetail;
  searchClinicResult: any[];
  allClinics: ClinicDetail[];
  filterClinicList: any[];
  centerCodeList: any[];
  searchClinicInput = '';
  displaySearch = false;
  preselectClinicId: number | null;

  private subscriptions: Subscription[] = [];
  public noUser = true;
  public showClinicList = false;
  public lockout = of(false);

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private ref: ChangeDetectorRef,
    private messageService: MessageService,
    private adminService: AdminService,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private floatSerivce: FloatService,
    private clinicService: ClinicService,
    private virtualLobbyService: VirtualLobbyService,
  ) {}
  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  ngOnInit() {
    this.subscriptions.push(
      this.clinicService.getClinic().subscribe((clinic) => {
        if (clinic) {
          this.selectedClinic = clinic;
          // Trigger change detection to update the view if clinic is selected from hierarchy page
          this.ref.detectChanges();
        }
      }),
    );

    this.subscriptions.push(
      this.sessionService.currentUser$.subscribe({
        next: (currentUser) => {
          this.setup(currentUser);
        },
      }),
    );
    this.checkLockout();
  }
  async setup(userInfo: UserLogin) {
    this.showClinicList = false;
    // handle the user no longer existing
    if (!userInfo) {
      this.noUser = true;
      this.ref.detectChanges();
      return;
    } else {
      this.noUser = false;
    }
    this.userLogin = userInfo.userLogin ? this.sessionService.getUserLogin().userLogin : '--';
    this.floatUser = this.sessionService.userIsFloat();
    this.swUri = environment.swUri;
    this.preselectClinicId = this.clinicService.getPreselectedClinic();
    const userId = userInfo.userId;
    this.showClinicList = true;
    this.providerService.getClinicList(userId.toString()).subscribe((clinics) => {
      this.allClinics = clinics;
      this.selectedClinic = this.preselectClinicId
        ? this.allClinics.filter((clinic) => clinic.IdCenter === this.preselectClinicId)[0] || this.allClinics[0]
        : this.allClinics[0];
      this.clinicService.setClinic(this.selectedClinic);
      this.searchClinicResult = this.getFirstClinics(clinics);
      this.filterClinicList = clinics;
      this.getVirtualLobbyNumber(this.selectedClinic['IdCenter']);
    });
    this.setUpperMenuLinks();
  }

  async logout() {
    this.router.navigate(['/logout']);
  }

  goToLink(nav: INavLink) {
    if (nav.subMenu) {
      return;
    }
    nav.url ? this.router.navigate([nav.url]) : (window.location.href = nav.externalUrl);
  }

  showSearch() {
    this.displaySearch = !this.displaySearch;
  }

  searchClinicInputChange() {
    this.filterClinicList = this.allClinics.filter((clinic) => {
      return clinic.CenterDisplayName.toLowerCase().includes(this.searchClinicInput.toLowerCase());
    });
    this.centerCodeList = this.allClinics.filter((c) => {
      return c.CenterCode.toLowerCase() === this.searchClinicInput.toLowerCase();
    });
    this.searchClinicResult = this.getFirstClinics(this.filterClinicList);
    this.ref.detectChanges();
  }

  selectClinicLink(clinic) {
    this.selectedClinic = clinic;
    this.clinicService.setClinic(this.selectedClinic);
    this.displaySearch = false;
    this.searchClinicInput = '';
    this.filterClinicList = this.allClinics;
    this.centerCodeList = [];
    this.searchClinicResult = this.getFirstClinics(this.filterClinicList);
    this.setCookiesAndSessionStorage();
    this.getVirtualLobbyNumber(this.selectedClinic['IdCenter']);
  }

  goToClinic() {
    if (!this.centerCodeList.length) {
      return this.messageService.add({
        severity: 'error',
        detail: `${this.searchClinicInput} is not a valid Clinic ID`,
      });
    }
    this.selectClinicLink(this.centerCodeList[0]);
  }

  private getFirstClinics(clinicList): any[] {
    return clinicList.length > 25 ? clinicList.slice(0, 25) : clinicList;
  }

  private setCookiesAndSessionStorage() {
    sessionStorage.setItem('fsw.angular._FMCSW_CENTERID', this.selectedClinic.IdCenter.toString());
    this.cookieService.set('LCENTER', this.selectedClinic.IdCenter.toString(), null, '/');
    const swCenterCookie = JSON.stringify({
      CENTERCODE: this.selectedClinic.CenterCode,
      CENTERID: this.selectedClinic.IdCenter,
      CENTERNAME: this.selectedClinic.CenterName,
    });
    this.cookieService.delete('SW_CENTER', '/', '.fmcschedule.com');
    this.cookieService.set('SW_CENTER', swCenterCookie, null, '/', '.fmcschedule.com');
    this.adminService.currentClinic$.next({
      label: `${this.selectedClinic.CenterName} (${this.selectedClinic.CenterCode})`,
      value: this.selectedClinic.IdCenter,
    });
    this.providerService.setSelectedClinicOnCFSession(this.selectedClinic.IdCenter.toString()).subscribe((res) => {
      if (!res.MYSESSION || !res.MYSESSION[0] || !res.MYSESSION[0].ISPILOT) {
        return;
      }
      sessionStorage.setItem('fsw.ISPILOT', res.MYSESSION[0].ISPILOT);
    });
  }

  private async setUpperMenuLinks() {
    this.navLinks = [
      {
        displayText: 'Staff',
        url: '/schedule/staff',
        hasAccess: this.sessionService.checkUserPermissionsAny(['SS']),
      },
      {
        displayText: 'Patient-old',
        externalUrl: `${this.swUri}/Scheduler01/#!/app/patient/pod-schedule/`,
        hasAccess: this.sessionService.checkUserPermissionsAny(['VS']),
      },
      {
        displayText: 'Patient',
        url: '/schedule/patient',
        hasAccess: this.sessionService.checkUserPermissionsAny(['VS']),
      },
      {
        displayText: 'WISE',
        url: '/dashboard',
        hasAccess: this.sessionService.checkUserPermissionsAny(['LP']),
      },
      {
        displayText: 'SWAC',
        url: `/swac/clinic`,
        hasAccess: this.sessionService.checkUserPermissionsAny(['SWAC', 'SWACPERM']),
        subMenu: [
          {
            displayText: 'Clinic Search',
            url: `/swac/clinic`,
            hasAccess: this.sessionService.checkUserPermissionsAny(['SWAC', 'SWACPERM']),
          },
          {
            displayText: 'SWAC Dashboard',
            url: `/swac/patients`,
            hasAccess:
              this.sessionService.checkUserPermissionsAny(['SWAC', 'SWACPERM']) &&
              !this.sessionService.checkUserPermissionsAny(['SWACRO']),
          },
        ],
      },
      {
        displayText: 'Virtual Lobby',
        url: `/virtual-lobby`,
        hasAccess: this.sessionService.checkUserPermissionsAny(['LOBBY', 'ROLOBBY']),
        counter: 0,
      },
      {
        displayText: 'Reports',
        url: `/admin/reports`,
        hasAccess:
          this.sessionService.checkUserPermissionsAny(['REPORTSNEW', 'REPORTS', 'OPTIMIZER']) ||
          this.sessionService.userIsSysAdmin(),
      },
      {
        displayText: 'Admin',
        hasAccess: this.sessionService.checkUserPermissionsAny(['ADMIN']),
        subMenu: [
          {
            displayText: 'Users',
            hasAccess: this.sessionService.checkUserPermissionsAny(['USERS']),
            url: `/admin/user`,
          },
          {
            displayText: 'Hierarchy',
            hasAccess: this.sessionService.userIsSysAdmin(),
            url: `/admin/hierarchy`,
          },
          {
            displayText: 'Configuration',
            hasAccess: this.sessionService.checkUserPermissionsAny(['CONFIG']),
            url: `/podconfig`,
          },
          {
            displayText: 'Templates',
            hasAccess: this.sessionService.checkUserPermissionsAny(['TEMPLATES']),
            externalUrl: `${this.swUri}/centerFileManager.cfm`,
          },
          {
            displayText: 'User Roles',
            hasAccess: this.sessionService.checkUserPermissionsAny(['USERROLES']),
            url: `/admin/user-roles`,
          },
          {
            displayText: 'Announcerator',
            hasAccess: this.sessionService.checkUserPermissionsAny(['MAINT']),
            url: `/admin/annoucerator`,
          },
          {
            displayText: 'Maintenance Mode',
            hasAccess: this.sessionService.checkUserPermissionsAny(['MAINT']),
            url: `/admin/maintenance`,
          },
          {
            displayText: 'Tooltips',
            hasAccess: this.sessionService.userIsSysAdmin(),
            url: '/dashboard/admin',
          },
        ],
      },
      {
        displayText: 'My Schedule',
        url: '/staff-schedule/my-schedule',
        hasAccess: this.sessionService.checkUserPermissionsAny(['_MYSCHEDULE']) || this.sessionService.userIsSysAdmin(),
        subMenu:
          // only show the drop down if you are eligible or permanent
          this.sessionService.checkUserPermissionsAny(['FLOATELIGIBLE'])
            ? [
                {
                  displayText: 'Float Settings',
                  url: '/float-pool/register/summary',
                  hasAccess: this.sessionService.checkUserPermissionsAny(['FLOATELIGIBLE']),
                },
                {
                  displayText: 'Available Shifts',
                  url: '/float-pool/provider/available-shifts',
                  hasAccess: this.sessionService.checkUserPermissionsAny(['FLOATELIGIBLE']),
                },
              ]
            : undefined,
      },
    ];
    if (!environment.requireSSO) {
      this.navLinks.push({
        displayText: 'Change Password',
        url: '/login/passwords',
        hasAccess: true,
      });
    }

    this.ref.detectChanges();

    this.setRegistrationStatus();
  }
  private setRegistrationStatus() {
    if (!this.sessionService.checkUserPermissionsAny(['FLOATELIGIBLE'])) return;

    this.floatSerivce.getRegistrationStatus().subscribe((registration) => {
      // you are already completed registration so carry on
      if (
        registration.status.completedNotification &&
        registration.status.completedClinics &&
        registration.status.completedSummary
      )
        return;

      let registrationUrl = '/float-pool/register/notifications';
      if (registration.status?.completedClinics) {
        registrationUrl = '/float-pool/register/summary';
      } else if (registration.status?.completedNotification) {
        registrationUrl = '/float-pool/register/clinics';
      }

      const myScheduleLink = this.navLinks.find((link) => link.displayText === 'My Schedule');
      // set the counter to 1 so that people know there is something there to do
      myScheduleLink.counter = 1;
      myScheduleLink.subMenu = [
        {
          displayText: 'Register Float',
          url: registrationUrl,
          hasAccess: this.sessionService.checkUserPermissionsAny(['FLOATELIGIBLE']),
        },
      ];

      this.ref.detectChanges();
    });
  }
  private async setVirtualLobbyCount(virtualLobbyCount) {
    const virtualLobbyLink = this.navLinks.find((link) => link.displayText === 'Virtual Lobby');
    virtualLobbyLink.counter = virtualLobbyCount;
    this.ref.detectChanges();
  }
  private getVirtualLobbyNumber(selectedClinicId) {
    this.providerService.getVirtualLobbyCount(selectedClinicId).subscribe((count) => {
      this.setVirtualLobbyCount(count);
    });
  }
  private checkLockout() {
    this.lockout = this.virtualLobbyService.getPatientRequiresPlacement().pipe(
      map(
        (patientRequiresPlacement) =>
          patientRequiresPlacement &&
          // Only people with the jail permission go to jail.
          this.sessionService.checkUserPermissionsAny(['VLJAIL']),
      ),
    );
  }
}

export interface INavLink {
  displayText: string;
  url?: string;
  hasAccess: boolean;
  externalUrl?: string;
  subMenu?: INavLink[];
  counter?: number;
}
