import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { PatientsEditModalComponent } from 'src/app/schedule-wise/schedule-patient/components/patients-edit-modal/patients-edit-modal.component';
import { DayLetters, FrequencyIntervalDays } from 'src/app/shared/enums/frequency-intervals';
import {
  convertIsoTimeToMinutes,
  convertIsoTimeToTimeString,
  convertMinutesToTimeString,
  convertTimeStringToIsoString,
} from 'src/app/shared/helpers/dateTime';
import { PatientModalComponent } from 'src/app/virtual-lobby/patient-modal/patient-modal.component';
import { VirtualLobbyService } from 'src/app/virtual-lobby/services/virtual-lobby.service';

import { IPodConfig } from '../../../../podconfig/shared/podconfig.models';
import { IPatientShiftEvent } from '../../models/patient-modal';
import {
  DEFAULT_ACCUITIES,
  IPatientTreatmentEvent,
  LONG_TURNOVER_CLASS,
  LONG_TURNOVER_TIME,
  SHORT_TURNOVER_CLASS,
  SHORT_TURNOVER_TIME,
  TREATMENT_CONFLICT_TIME,
  TURNOVER_CONFLICT_CLASS,
} from '../../models/schedule-patient.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { CreateOpenChairModalComponent } from '../create-open-chair-modal/create-open-chair-modal.component';
import { ExistingPatientModalComponent } from '../existing-patient-modal/existing-patient-modal.component';
import { PatientsModalService } from '../patients-modal/patients-modal.service';
import { SharedChairEditModalComponent } from '../shared-chair-edit-modal/shared-chair-edit-modal.component';

@Component({
  selector: 'app-schedule-patient-treatment',
  templateUrl: './schedule-patient-treatment.component.html',
  styleUrls: ['./schedule-patient-treatment.component.scss'],
  providers: [DialogService],
})
export class SchedulePatientTreatmentComponent implements OnChanges {
  @Input() treatments: IPatientTreatmentEvent[];
  @Input() legacyIdCenter: number;
  @Input() wizardMode: boolean;
  @Input() compareMode: boolean;
  @Input() selectedSharedChair: number = 0;
  @Input() podName: string;
  @Input() chairName: string;
  @Input() sharedChairEditMode = false;
  @Input() currentDragTargetId: number | null = null;
  @Input() podConfig: IPodConfig[];
  @Output() dragTargetChange = new EventEmitter<number | null>();
  @Output() dragStartEvent = new EventEmitter<IPatientTreatmentEvent>();
  @Output() dragAndDropEvent = new EventEmitter<{ source: IPatientTreatmentEvent; target: IPatientTreatmentEvent }>();
  @Output() selectedSharedChairChange: EventEmitter<number> = new EventEmitter();
  @Output() timeChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  public treatment: IPatientTreatmentEvent;
  public durationDisplay = '';
  public startTimeField = '';
  public closed = false;
  public open = false;
  public empty = false;
  public transient = false;
  public emptySharedChairSlot = false;
  public preTurnoverClass = '';
  public postTurnoverClass = '';
  public preTurnoverMessage = '';
  public postTurnoverMessage = '';
  public startTime = '--:--';
  public endTime = '--:--';
  public menuItems: MenuItem[];
  public popoverVisible = false;
  public showCompare = false;
  public compareDisplay = '';
  public invalidStart = false;
  public dayLetters = DayLetters;
  private initialStartTime = '--:--';
  private editSharedChairModal: DynamicDialogRef;
  private existingPatientModal: DynamicDialogRef;
  public isCurrentDragTarget: boolean = false;
  private draggedTreatment: IPatientTreatmentEvent | null = null;
  public chairReasons: any[] = [];
  public chairAvailabilityData: any;
  public showChairAvailabilityModal: boolean = false;
  public highAcuity = false;

  constructor(
    public dialogService: DialogService,
    private patientsModalService: PatientsModalService,
    private ms: MessageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private patientScheduleService: PatientScheduleService,
    private virtualLobbyService: VirtualLobbyService,
  ) {}

  ngOnChanges(changes?: {
    treatments?: IPatientTreatmentEvent;
    compareMode?: boolean;
    selectedSharedChair?: number;
  }): void {
    this.treatment = this.treatments[this.selectedSharedChair] || this.treatments[0] || null;
    this.updateStatuses();
    if (this.treatment) {
      this.updateTimeDisplay();
      this.updateTime();
      this.calculateTurnoverIssues();
      this.calculateHighAcuity();
    }
    this.updateMenuItems();

    if (changes?.compareMode) {
      if (this.treatment) {
        this.showCompare = this.compareMode && (!!this.treatment.PrevChair || !!this.treatment.PrevStartTime);
      }
    }
  }

  private openEditPatients(): void {
    this.dialogService.open(PatientsEditModalComponent, {
      header: 'Edit Patient Information',
      data: {
        patientId: this.treatment.PatientId,
        legacyIdClinic: this.legacyIdCenter,
        newPatient: false,
      },
    });
  }

  private openNewPatients(): void {
    this.dialogService.open(PatientsEditModalComponent, {
      header: 'Add New Patient',
      data: {
        treatment: this.treatment,
        legacyIdClinic: this.legacyIdCenter,
        newPatient: true,
      },
    });
  }

  private openEditSharedChair(): void {
    let modalTreatments = this.treatments;
    if (this.treatments.length === 1) {
      // Create virtual treatments so the Shared Chair modal has 3 slots
      modalTreatments = [cloneDeep(this.treatments[0]), cloneDeep(this.treatments[0]), cloneDeep(this.treatments[0])];
      for (let i = 0; i < modalTreatments.length; i++) {
        modalTreatments[i].SharedChairDay = FrequencyIntervalDays[modalTreatments[i].FrequencyInterval][i];
      }
    }
    this.editSharedChairModal = this.dialogService.open(SharedChairEditModalComponent, {
      header: 'Edit Shared Chair',
      width: '50rem',
      closable: false,
      data: {
        treatments: modalTreatments.map((t) => {
          return [t];
        }),
        legacyIdCenter: this.legacyIdCenter,
        podName: this.podName,
        chairName: this.chairName,
        shiftNumber: this.treatment.ShiftNumber,
      },
    });
    this.editSharedChairModal.onClose.subscribe((res) => {
      if (res) this.refreshData.emit(true);
    });
  }
  private openExistingPatientModal(): void {
    this.existingPatientModal = this.dialogService.open(ExistingPatientModalComponent, {
      header: 'Add Existing Patient',
      width: '60rem',
      closable: true,
      data: {
        legacyIdCenter: this.legacyIdCenter,
        treatment: this.treatment,
      },
    });
    this.existingPatientModal.onClose.subscribe((res) => {
      if (res) this.refreshData.emit(res);
    });
  }

  public showPopover(): void {
    if (!this.sharedChairEditMode && !this.wizardMode) this.popoverVisible = true;
  }
  public hidePopover(): void {
    this.popoverVisible = false;
  }

  public onStartTimeChange(): void {
    const startTimeDetails: string[] = this.startTime.split(':');
    if (
      !isNaN(+startTimeDetails[0]) &&
      !isNaN(+startTimeDetails[1]) &&
      +startTimeDetails[0] <= 23 &&
      +startTimeDetails[1] <= 59
    ) {
      this.invalidStart = false;
      if (this.initialStartTime !== this.startTime) {
        const patientShiftEvent: IPatientShiftEvent = {
          idScheduleEvent: this.treatment.ScheduleEventId,
          startTime: this.startTime,
          durationInMinutes: this.treatment.Duration,
          acuityOnPreMinutes: this.treatment.AcuityOnPre,
          acuityOffPreMinutes: this.treatment.AcuityOffPre,
          acuityOnPostMinutes: this.treatment.AcuityOnPost,
          acuityOffPostMinutes: this.treatment.AcuityOffPost,
        };

        this.patientsModalService.updatePatientSchedule(patientShiftEvent).subscribe({
          next: () => {
            this.updateTime();
            this.timeChangeEvent.emit(true);
            this.ms.add({ severity: 'success', detail: 'This shift has been successfully updated.' });
          },
          error: (err) => {
            this.ms.add({ severity: 'error', detail: err.errorMsg });
          },
        });
      }
    } else {
      this.invalidStart = true;
    }
  }

  public changeSharedChair(i: number): void {
    this.selectedSharedChair = i;
    this.treatment = this.treatments[this.selectedSharedChair] || this.treatments[0] || null;
    this.updateStatuses();
    this.updateTimeDisplay();
    this.updateTime();
    this.updateMenuItems();
    this.calculateTurnoverIssues();
    this.selectedSharedChairChange.emit(i);
  }

  private updateTime(): void {
    if (this.treatment.StartTime) {
      this.initialStartTime = this.startTime;
      const newStartTime = convertIsoTimeToMinutes(this.treatment.StartTime);
      this.endTime = convertMinutesToTimeString(newStartTime + this.treatment.Duration);
      this.treatment.StartTime = convertTimeStringToIsoString(this.startTime);
    }
  }

  private calculateTurnoverIssues(): void {
    this.preTurnoverClass = '';
    if (this.treatment.PreTurnover !== undefined) {
      this.preTurnoverClass =
        this.treatment.PreTurnover > LONG_TURNOVER_TIME
          ? LONG_TURNOVER_CLASS
          : this.treatment.PreTurnover <= TREATMENT_CONFLICT_TIME
          ? TURNOVER_CONFLICT_CLASS
          : this.treatment.PreTurnover < SHORT_TURNOVER_TIME
          ? SHORT_TURNOVER_CLASS
          : '';
    }
    this.postTurnoverClass = '';
    if (this.treatment.PostTurnover !== undefined) {
      this.postTurnoverClass =
        this.treatment.PostTurnover > LONG_TURNOVER_TIME
          ? LONG_TURNOVER_CLASS
          : this.treatment.PostTurnover <= TREATMENT_CONFLICT_TIME
          ? TURNOVER_CONFLICT_CLASS
          : this.treatment.PostTurnover < SHORT_TURNOVER_TIME
          ? SHORT_TURNOVER_CLASS
          : '';
    }
    this.preTurnoverMessage =
      this.preTurnoverClass === LONG_TURNOVER_CLASS
        ? 'LONG TURNOVER WITH PREVIOUS SHIFT'
        : this.preTurnoverClass === TURNOVER_CONFLICT_CLASS
        ? 'CONFLICT WITH PREVIOUS SHIFT'
        : this.preTurnoverClass === SHORT_TURNOVER_CLASS
        ? 'SHORT TURNOVER WITH PREVIOUS SHIFT'
        : '';
    this.postTurnoverMessage =
      this.postTurnoverClass === LONG_TURNOVER_CLASS
        ? 'LONG TURNOVER WITH NEXT SHIFT'
        : this.postTurnoverClass === TURNOVER_CONFLICT_CLASS
        ? 'CONFLICT WITH NEXT SHIFT'
        : this.postTurnoverClass === SHORT_TURNOVER_CLASS
        ? 'SHORT TURNOVER WITH NEXT SHIFT'
        : '';
  }

  private updateStatuses(): void {
    // Closed = unavailable chair
    this.closed = this.treatment?.ChairStatus?.toLowerCase().indexOf('unavail') >= 0;

    // Open = available chair - aka 'open chair'
    this.open =
      this.treatment?.ChairStatus.toLowerCase().indexOf('unavail') < 0 &&
      !this.treatment?.LastName &&
      !!this.treatment?.StartTime;

    // Empty = no data - aka 'blue-plus-sign'
    this.empty =
      !this.treatment ||
      (this.treatment?.ChairStatus.toLowerCase().indexOf('unavail') < 0 &&
        !this.treatment?.LastName &&
        !this.treatment?.StartTime);

    // EmptySharedChairSlot = no data in a shared chair
    this.emptySharedChairSlot = !!this.treatment?.SharedChairDay && (this.open || this.empty);

    this.transient = this.treatment?.PatientNeeds.includes('Transient') || this.treatment?.PatientType === 2;
  }

  private updateTimeDisplay(): void {
    this.durationDisplay = `${Math.floor(this.treatment.Duration / 60)}:${(this.treatment.Duration % 60)
      .toString()
      .padStart(2, '0')}`;

    if (this.treatment.PrevStartTime) {
      const prevStartTime = convertIsoTimeToMinutes(this.treatment.PrevStartTime);
      const prevEndTime = convertMinutesToTimeString(prevStartTime + this.treatment.Duration);

      this.compareDisplay = `OLD: ${convertIsoTimeToTimeString(this.treatment.PrevStartTime)}-${prevEndTime}`;
    }
    if (this.treatment.PrevPod && this.treatment.PrevChair) {
      const podChairCompare = `${this.treatment.PrevPod} - ${this.treatment.PrevChair}`;
      this.compareDisplay = this.compareDisplay
        ? `${this.compareDisplay}, ${podChairCompare}`
        : `PREV: ${podChairCompare}`;
    }
    this.startTime = this.treatment.StartTime ? convertIsoTimeToTimeString(this.treatment.StartTime) : '--:--';
  }

  private calculateHighAcuity() {
    this.highAcuity =
      this.treatment &&
      (this.treatment.AcuityOnPre > DEFAULT_ACCUITIES.AcuityOnPre ||
        this.treatment.AcuityOnPost > DEFAULT_ACCUITIES.AcuityOnPost ||
        this.treatment.AcuityOffPre > DEFAULT_ACCUITIES.AcuityOffPre ||
        this.treatment.AcuityOffPost > DEFAULT_ACCUITIES.AcuityOffPost);
  }

  public onDragStart(event: DragEvent, treatment: IPatientTreatmentEvent): void {
    if (
      treatment.ChairStatus.toLowerCase().indexOf('unavail') >= 0 ||
      this.wizardMode ||
      this.empty ||
      this.treatments.length > 1
    ) {
      event.preventDefault();
      return;
    }

    this.draggedTreatment = treatment;
    this.dragStartEvent.emit(treatment);
  }

  public onDragOver(event: DragEvent, treatment: IPatientTreatmentEvent): void {
    event.preventDefault();

    const targetId = treatment.ScheduleEventId;

    if (!targetId || this.treatments.length > 1) {
      // Skip highlighting for unavailable chairs
      if (this.isCurrentDragTarget) {
        this.isCurrentDragTarget = false;
        this.dragTargetChange.emit(null);
      }
      return;
    }

    if (this.draggedTreatment?.ScheduleEventId !== targetId) {
      if (this.currentDragTargetId !== targetId) {
        this.dragTargetChange.emit(targetId);
        this.currentDragTargetId = targetId;
      }
      this.isCurrentDragTarget = true;
    } else {
      this.isCurrentDragTarget = false;
    }
  }

  public onDragLeave(event: DragEvent): void {
    const relatedElement = event.relatedTarget as HTMLElement;

    if (!relatedElement || !relatedElement.closest('.treatment')) {
      this.dragTargetChange.emit(null);
    }
    this.isCurrentDragTarget = false;
  }

  public onDrop(event: DragEvent, treatment: IPatientTreatmentEvent): void {
    event.preventDefault();
    const source = this.draggedTreatment;
    const target = treatment;
    if (source?.ScheduleEventId === target?.ScheduleEventId || this.treatments.length > 1) {
      return; // Exit early if source and target are the same
    }
    this.isCurrentDragTarget = false;
    this.dragAndDropEvent.emit({ source, target });
  }

  public onDragEnd(): void {
    this.draggedTreatment = null;
    this.dragTargetChange.emit(null);
  }

  private updateMenuItems(): void {
    if (this.closed) {
      this.menuItems = [
        {
          label: 'Edit Chair Availability',
          icon: 'pi pi-pencil',
          command: () => {
            this.openEditChairAvailability();
          },
        },
      ];
    } else if (this.emptySharedChairSlot) {
      this.menuItems = [
        {
          label: 'Edit Shared Chair',
          icon: 'pi pi-users',
          command: () => {
            this.openEditSharedChair();
          },
        },
      ];
    } else if (this.treatment.PatientLobbyId) {
      // this patient needs to be placed
      this.menuItems = [
        {
          label: 'Place patient',
          icon: 'pi pi-users',
          command: () => {
            this.openPlacePatient();
          },
        },
      ];
    } else if (this.open || this.empty) {
      this.menuItems = [
        {
          label: 'Add New Patient',
          icon: 'pi pi-user-plus',
          command: () => {
            this.openNewPatients();
          },
        },
        {
          label: 'Add Existing Patient',
          icon: 'pi pi-user',
          command: () => {
            this.openExistingPatientModal();
          },
        },
        {
          label: 'Edit Chair Availability',
          icon: 'pi pi-pencil',
          command: () => {
            this.openEditChairAvailability();
          },
        },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Edit Patient',
          icon: 'pi pi-user-edit',
          command: () => {
            this.openEditPatients();
          },
        },
        {
          label: 'Remove Patient',
          icon: 'pi pi-user-minus',
          command: () => {
            this.removePatient();
          },
        },
        ...(this.treatments.length > 1
          ? [
              {
                label: 'Edit Shared Chair',
                icon: 'pi pi-users',
                command: () => {
                  this.openEditSharedChair();
                },
              },
            ]
          : [
              {
                label: 'Create Shared Chair',
                icon: 'pi pi-users',
                command: () => {
                  this.openEditSharedChair();
                },
              },
            ]),
      ];
    }
  }

  openPlacePatient() {
    this.virtualLobbyService.getPatientByLobbyId(this.treatment.PatientLobbyId).subscribe({
      next: (patient) => {
        this.dialogService
          .open(PatientModalComponent, {
            header: 'Place Patient',
            data: {
              patient,
              submit: async (patientUploadData) => {
                await firstValueFrom(this.virtualLobbyService.editPatient(patientUploadData));
                await firstValueFrom(this.virtualLobbyService.placePatient(patientUploadData));
              },
              legacyIdCenter: this.legacyIdCenter,
            },
          })
          .onClose.subscribe({
            next: async (response: boolean) => {
              if (response) {
                delete this.treatment.PatientLobbyId;
                this.updateMenuItems();
              }
            },
          });
      },
    });
  }

  openEditChairAvailability(): void {
    if (this.empty) {
      const ref = this.dialogService.open(CreateOpenChairModalComponent, {
        header: 'Enter Start Time for Open Chair',
        width: '400px',
        closable: true,
      });

      ref.onClose.subscribe((result) => {
        if (result?.startTime) {
          this.createOpenChair(result.startTime);
        } else {
          this.showChairAvailabilityModal = false;
        }
      });
      return;
    }

    this.chairAvailabilityData = {
      podConfig: this.podConfig,
      centerId: this.legacyIdCenter,
      podName: this.podName,
      chairName: this.chairName,
      eventScheduleID: this.treatment.IdEventSchedule,
      shiftNumber: this.treatment?.ShiftNumber || 1,
      chairStatus: this.treatment?.ChairStatus || { id: 'AVAILABLE', display: 'Available' },
      chairId: this.treatment?.ChairId,
      statusReason: this.treatment?.StatusReason,
      additionalInfo: this.treatment?.AdditionalInfo,
    };
    this.showChairAvailabilityModal = true;
  }

  private createOpenChair(startTime: string, duration?: number): void {
    this.patientScheduleService
      .createOpenChair(
        this.treatment?.IdEventSchedule,
        this.treatment.ChairId,
        this.treatment.ShiftNumber,
        startTime,
        duration,
      )
      .subscribe({
        next: () => {
          this.ms.add({ severity: 'success', detail: 'Open chair created successfully.' });
          this.closeModal({ updated: true });
        },
        error: (err) => {
          this.ms.add({ severity: 'error', detail: err.errorMsg });
        },
      });
  }

  closeModal(updatedData?: any): void {
    this.showChairAvailabilityModal = false;
    if (updatedData) {
      this.refreshData.emit(true);
    }
  }

  private removePatient(): void {
    if (this.treatments.length) {
      this.confirmationService.confirm({
        message:
          this.treatments.length > 1
            ? 'Are you sure you want to remove ALL of these patients?'
            : 'Are you sure you want to remove this patient?',
        header: 'Confirm Removal',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Remove',
        rejectLabel: 'Cancel',
        accept: async () => {
          let error = false;
          // These procedures have to be synchronous to prevent deadlocks
          // therefore we use a classic for loop
          for (let i = 0; i < this.treatments.length; i++) {
            const tx = this.treatments[i];
            const res = await firstValueFrom(
              this.patientScheduleService.closeTreatmentEvent(
                tx.IdEventSchedule,
                tx.ChairId,
                tx.ShiftNumber,
                tx.SharedChairDay ? tx.SharedChairDay - 1 : undefined,
                tx.StartTime,
                tx.Duration,
              ),
            );
            if (res.statusCode !== 200) {
              error = true;
            }
          }
          if (!error) {
            this.messageService.add({
              severity: 'success',
              summary: 'Remove Patient',
              detail:
                this.treatments.length > 1 ? 'These patients have been removed.' : 'This patient has been removed.',
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Patient Error',
              detail: this.treatments.length > 1 ? 'Error removing these patients.' : 'Error removing this patient.',
            });
          }
          this.refreshData.emit(true);
        },
      });
    }
  }
}
