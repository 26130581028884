<form [formGroup]="contactForm" class="fill invalid-full-border">
  <span class="dialog-info-title">We're here to help!</span>
  <span class="dialog-info">
    Just let us know what the problem is below and we'll get back to you as soon as we can.&nbsp;
    <a href="https://content.intranet.fmcna.com/schedulewise/" target="_blank" rel="noopener noreferrer">
      Additional information
    </a>
  </span>
  <div class="grid">
    <div class="col-12">
      <label for="name">Name</label>
      <span class="required">*</span>
    </div>
    <div class="col-12">
      <input
        [ngClass]="{
          'field-error':
            this.errorMessageDisplayed && contactForm.get('name').invalid && contactForm.get('name').touched
        }"
        formControlName="name"
        id="name"
        name="name"
        type="text"
        pInputText
        placeholder="Name" />
    </div>
    <div
      *ngIf="this.errorMessageDisplayed && contactForm.get('name').invalid && contactForm.get('name').touched"
      class="grid field-messages-error">
      <div class="col-12 p-messages-error p-corner-all">
        <i class="error-icon-left pi pi-exclamation-triangle"></i>
        {{ errorMessageDisplayed }}
      </div>
    </div>

    <div class="col-12">
      <label for="email">Email</label>
      <span class="required">*</span>
    </div>
    <div class="col-12">
      <input
        [ngClass]="{
          'field-error':
            this.errorMessageDisplayed && contactForm.get('email').invalid && contactForm.get('email').touched
        }"
        formControlName="email"
        id="email"
        name="email"
        type="email"
        pInputText
        placeholder="Email" />
    </div>
    <div
      *ngIf="this.errorMessageDisplayed && contactForm.get('email').invalid && contactForm.get('email').touched"
      class="grid field-messages-error">
      <div class="col-12 p-messages-error p-corner-all">
        <i class="error-icon-left pi pi-exclamation-triangle"></i>
        {{ errorMessageDisplayed }}
      </div>
    </div>

    <div class="col-12">
      <label for="phone">Phone</label>
      <span class="required">*</span>
    </div>
    <div class="col-12">
      <p-inputMask
        class="input-mask"
        [ngClass]="{
          'field-mask-error':
            this.errorMessageDisplayed && contactForm.get('phone').invalid && contactForm.get('phone').touched
        }"
        formControlName="phone"
        id="phone"
        name="phone"
        mask="(999) 999-9999"
        placeholder="(999) 999-9999"></p-inputMask>
    </div>
    <div
      *ngIf="this.errorMessageDisplayed && contactForm.get('phone').invalid && contactForm.get('phone').touched"
      class="grid field-messages-error">
      <div class="col-12 p-messages-error p-corner-all">
        <i class="error-icon-left pi pi-exclamation-triangle"></i>
        {{ errorMessageDisplayed }}
      </div>
    </div>

    <div class="col-12">
      <label for="message">Message</label>
      <span class="required">*</span>
    </div>
    <div class="col-12">
      <textarea
        [ngClass]="{
          'field-error':
            this.errorMessageDisplayed && contactForm.get('message').invalid && contactForm.get('message').touched
        }"
        id="message"
        name="message"
        placeholder="Message"
        type="text"
        pInputText
        formControlName="message"></textarea>
    </div>
    <div
      *ngIf="this.errorMessageDisplayed && contactForm.get('message').invalid && contactForm.get('message').touched"
      class="grid field-messages-error">
      <div class="col-12 p-messages-error p-corner-all">
        <i class="error-icon-left pi pi-exclamation-triangle"></i>
        {{ errorMessageDisplayed }}
      </div>
    </div>
  </div>
</form>
<div class="footer pt-3 flex justify-content-between">
  <div class="footer-left-content">Phone: 844-362-2222 | Email: schedulewise&#64;fmc-na.com</div>
  <div class="footer-right-content">
    <button class="p-button-secondary" type="button" pButton (click)="dynamicDialogRef.close()" label="Cancel"></button>
    <button
      class="p-button-secondary"
      type="button"
      pButton
      (click)="onSendIt()"
      [disabled]="contactForm.invalid"
      label="Send It!"></button>
  </div>
</div>
